<template>
  <v-content class="mypage">
    <div class="px-3 mt-5">
      <p class="fontsize13">機種変更・再登録（再インストール）の方は、会員IDとPINコードを入力し、「引き継ぐ」をタップすると以前の登録情報を引き継げます。</p>

      <v-divider class="my-5"></v-divider>
      <ValidationObserver ref="observer">
        <v-row class>
          <v-col cols="12" sm="6" md="3">
            <ValidationProvider v-slot="{ errors }" name="会員ID" rules="required|numeric|max:11">
              <v-text-field
                v-model="formData.user_id"
                label="会員ID"
                maxlength="11"
                placeholder="会員IDを入力してください"
                :error-messages="errors"
                required
              ></v-text-field>
            </ValidationProvider>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <ValidationProvider v-slot="{ errors }" name="PINコード" rules="required|alpha_num|max:32">
              <v-text-field
                v-model="formData.exchange_code"
                label="PINコード"
                maxlength="32"
                placeholder="PINコードを入力してください"
                :error-messages="errors"
                required
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>

      <v-row class="caption" justify="center" align="center">
        <div class="d-flex">
          <v-checkbox v-model="checkbox" class="fontsize11 ma-0 pa-0"></v-checkbox>
          <router-link to="/info/rule">利用規約</router-link>に同意する
        </div>
      </v-row>

      <div class="text-center mb-7">
        <v-btn
          rounded
          class="maincolor-bg title"
          x-large
          v-bind:disabled="checkbox==false"
          @click.stop="handover()"
        >アカウントを引き継ぐ</v-btn>
      </div>

      <div class="text-center">
        <v-btn rounded class="graybtn" depressed @click="cancelbtn()">キャンセルする</v-btn>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-content>
</template>

<script>
import { required, numeric, max, alpha_num } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('numeric', {
  ...numeric,
  message: '数字のみが利用できます。',
})

extend('alpha_num', {
  ...alpha_num,
  message: '英数字のみが利用できます。',
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください。',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    checkbox: false,
    formData: {
      exchange_code: '',
      user_id: '',
    },
    init: [],
    loading: false,
    result: null,
    routing: null,
  }),
  async created() {
    this.init = this.storageGet('*')
  },
  methods: {
    handover: function(){
      this.$refs.observer.validate().then(async result => {
        if (result) {
          try {
            //loadingを表示
            this.loading = true
            // 引き継ぎAPI実行
            var res = await this.apiCall('/user/update', this.formData, 'post');
            if (!res) {
              this.loading = false;
              return false;
            }
            // 引き継き後のデータ保存
            this.storageSave(JSON.stringify({
              'device_id': res.device_id,
              'device_uid': res.device_uid,
            }))

            /* ルーティングAPIを叩く */
            var routing_req = { params: {
              'sid': this.init.sid,
              'device_uid': res.device_uid,
              'key': this.init.key,
              'device_id': res.device_id,
              'os': this.init.os,
              'feature_id': 'top',
              'service_cd': this.init.service_cd
            }};
            var routing_res = await this.apiCall('/user/routing', routing_req, 'get');
            if (!routing_res) return false;
            this.$set(this, 'routing', routing_res);

            var tonative_req = {
              'destination': routing_res.destination,
              'feature_id': routing_res.feature_id,
              'screen_id': routing_res.screen_id,
              'transition_type': routing_res.transition_type,
              'device_uid': res.device_uid,
              'device_id': res.device_id,
            };

            if (!(this.screenTransition(tonative_req))) {
              this.loading = false
              this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
            }

          } catch(e) {
            this.loading = false
            this.callDialog('エラー', '引き継ぎができませんでした。正しいIDとPINを入力してください。', 2);
          }
        }
      })
    },
    cancelbtn: function(){
      var tonative_req = {
        'destination': 'mypageactivity',
        'feature_id': 'mypage',
        'screen_id': 'mypage',
        'transition_type': 2,
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    }
  }
};
</script>

<style scoped>
.fontsize11 >>> label {
  font-size: 13px;
}
</style>
